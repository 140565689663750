import { useState, useEffect } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import json from "./data/data.json";
import "./App.css";
import Home from "./pages/Home";
import Rotation from "./pages/Rotation";
import RotationInstitution from "./components/RotationInstitution";
import Requests from "./pages/Requests";

function App() {
    const location = useLocation();
    const [currentProvider, setCurrentProvider] = useState("0");
    // Uncomment this and comment the next two for local development.
    // You can copy the json data from chrome into the src/data/data.json for up to date data
    // const [data, setData] = useState(json);
    // const [dataToUse, setDataToUse] = useState([...data.data.stats]);
    const [data, setData] = useState([]);
    const [dataToUse, setDataToUse] = useState([]);

    const [typeOfDataToShow, setTypeOfDataToShow] = useState("0");
    const [countryDataToUse, setCountryDataToUse] = useState("0");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timeSelector, setTimeSelector] = useState("0");

    useEffect(() => {
        let actualStartDate = subtractHours(new Date(), 1);
        setStartDate(actualStartDate);
        fetchData(actualStartDate, endDate);
    }, []);

    const subtractHours = (date, hours) => {
        date.setHours(date.getHours() - hours);
        return date;
    };

    const fetchData = (start, end) => {
        setLoading(true);
        fetch(
            `/Statistics/Institute?start=${start.valueOf()}&end=${end.valueOf()}`,
            {
                credentials: "include",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                getProviderData(currentProvider, data);
                setLoading(false);
                getRotationLogs(data);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.message);
            });
    };

    const getRotationLogs = (data) => {
        const orderCA = [
            "RBC",
            "CIBC",
            "Scotiabank",
            "TD",
            "Tangerine",
            "Desjardins",
            "BMO",
            "National Bank",
            "Simplii",
            "ATB Financial",
            "HSBC",
            "Meridian",
            "EQ Bank",
            "Coast Capital",
            "Laurentian",
            "Vancity",
        ];
        const orderUS = [
            "Bank of America",
            "Wells Fargo",
            "Chime Bank",
            "Chase Bank",
            "PNC Bank",
            "Navy Federal",
            "Capital One",
            "Truist",
            "US Bank",
            "Citibank",
            "TD Bank",
            "USAA",
            "Bank of the West",
        ];

        let dataFiltered = [];

        data.data.stats.forEach((element) => {
            if (
                orderCA.includes(element.institution) ||
                orderUS.includes(element.institution)
            ) {
                dataFiltered.push(element);
            }
        });

        // Logging CA data ROTATION
        let CANA = "";
        let CASR = "";
        let CACR = "";

        orderCA.forEach((element) => {
            let el = dataFiltered.find((e) => e.institution === element);
            if (el == null) {
                CANA = CANA + element + ",";
                CASR = CASR + 0 + ",";
                CACR = CACR + 0 + ",";
                return;
            }
            CANA = CANA + el.institution + ",";
            CASR = CASR + el.successRate.toFixed(2) + ",";
            CACR = CACR + el.cardConversionRate.toFixed(2) + ",";
        });

        console.log("Order", CANA.slice(0, -1));
        console.log("SR", CASR.slice(0, -1));
        console.log("CR", CACR.slice(0, -1));

        // Logging US data ROTATION
        let USNA = "";
        let USSR = "";
        let USCR = "";

        orderUS.forEach((element) => {
            let el = dataFiltered.find((e) => e.institution === element);
            if (el == null) {
                USNA = USNA + element + ",";
                USSR = USSR + 0 + ",";
                USCR = USCR + 0 + ",";
                return;
            }
            USNA = USNA + el.institution + ",";
            USSR = USSR + el.successRate.toFixed(2) + ",";
            USCR = USCR + el.cardConversionRate.toFixed(2) + ",";
        });

        console.log("Order", USNA.slice(0, -1));
        console.log("SR", USSR.slice(0, -1));
        console.log("CR", USCR.slice(0, -1));
    };

    const getDataToUse = (provider) => {
        setCurrentProvider(provider);
        getProviderData(provider, data);
    };

    const getProviderData = (provider, data) => {
        switch (provider) {
            case "0":
                setDataToUse([...data.data.stats]);
                break;
            case "1":
                setDataToUse([...data.data.flinks]);
                break;
            case "2":
                setDataToUse([...data.data.fiserv]);
                break;
            case "3":
                setDataToUse([...data.data.sophtron]);
                break;
            case "4":
                setDataToUse([...data.data.contextEngine]);
                break;
            case "5":
                setDataToUse([...data.data.openBanking]);
                break;
            case "6":
                setDataToUse([...data.data.mx]);
                break;
            default:
                setDataToUse([...data.data.stats]);
                break;
        }
    };

    const setDate = (option) => {
        setTimeSelector(option);
        let date = new Date();
        switch (option) {
            case "0":
                setStartDate(subtractHours(date, 1));
                setEndDate(new Date());
                break;
            case "1":
                setStartDate(new Date(new Date().setHours(0, 0, 0)));
                setEndDate(date);
                break;
            case "2":
                setStartDate(subtractHours(date, 24));
                setEndDate(new Date());
                break;
            case "3":
                setStartDate(subtractHours(date, 8));
                setEndDate(new Date());
                break;
            case "4":
                setStartDate(subtractHours(date, 4));
                setEndDate(new Date());
                break;
            default:
                setStartDate(new Date());
                setEndDate(subtractHours(date, 1));
                break;
        }
    };

    return (
        <section className="main">
            <header>
                <div className="selectors">
                    {location.pathname === "/rotation" && (
                        <select
                            onChange={(e) =>
                                setCountryDataToUse(e.target.value)
                            }
                            value={countryDataToUse}
                            className="data--type"
                        >
                            <option value={0}>CA</option>
                            <option value={1}>US</option>
                        </select>
                    )}
                    {location.pathname === "/" && (
                        <select
                            onChange={(e) =>
                                setTypeOfDataToShow(e.target.value)
                            }
                            value={typeOfDataToShow}
                            className="data--type"
                        >
                            <option value={0}>Data Type</option>
                            <option value={0}>Institution</option>
                            <option value={1}>Institution Nightly</option>
                            <option value={2}>Client</option>
                            <option value={3}>Client Nightly</option>
                        </select>
                    )}
                    {typeOfDataToShow === "0" && (
                        <select
                            onChange={(e) => getDataToUse(e.target.value)}
                            value={currentProvider}
                            className="providers--selector"
                        >
                            <option value={0}>All</option>
                            <option value={1}>Flinks</option>
                            <option value={2}>Fiserv</option>
                            <option value={3}>Sophtron</option>
                            <option value={4}>ContextEngine</option>
                            <option value={5}>OpenBanking</option>
                            <option value={6}>Mx</option>
                        </select>
                    )}
                </div>

                {location.pathname === "/" && (
                    <Link className="nav--link" to="/rotation">
                        Top FIs
                    </Link>
                )}
                {location.pathname !== "/" && (
                    <Link className="nav--link" to="/">
                        Home
                    </Link>
                )}

                <div className="dates">
                    <select
                        onChange={(e) => setDate(e.target.value)}
                        value={timeSelector}
                        className="data--type"
                    >
                        <option value={0}>Last 1 hour</option>
                        <option value={1}>Today</option>
                        <option value={2}>Last 24 hours</option>
                        <option value={3}>Last 8 hours</option>
                        <option value={4}>Last 4 hours</option>
                    </select>
                    <span>
                        <label>Start</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            className="date--picker"
                        />
                    </span>

                    <span>
                        <label>End</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            className="date--picker"
                        />
                    </span>

                    <button
                        className="btn--submit"
                        onClick={() => fetchData(startDate, endDate)}
                    >
                        Submit
                    </button>
                </div>
            </header>

            <Routes>
                <Route
                    path="/"
                    element={
                        <Home
                            dataToUse={dataToUse}
                            typeOfDataToShow={typeOfDataToShow}
                            error={error}
                            loading={loading}
                        />
                    }
                />
                <Route
                    path="/rotation"
                    element={
                        <Rotation
                            dataToUse={dataToUse}
                            typeOfDataToShow={typeOfDataToShow}
                            error={error}
                            loading={loading}
                            country={countryDataToUse}
                        />
                    }
                />
                <Route
                    path="/rotation/institution"
                    element={<RotationInstitution dataToUse={dataToUse} />}
                />
                <Route
                    path="/requests"
                    element={
                        <Requests startDate={startDate} endDate={endDate} />
                    }
                />
            </Routes>
        </section>
    );
}

export default App;
