import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Table from "../components/table/Table";
import Error from "../components/Error";

const Requests = ({ startDate, endDate }) => {
    let [params] = useSearchParams();

    const [institution] = useState(params.get("name"));

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        fetch(
            
            `/Stats/RequestsDetailByInstitution?institution=${institution}&start=${startDate.valueOf()}&end=${endDate.valueOf()}&query=total`,
            {
                credentials: "include",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setData(data);        
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.message);
            });
    };

    const keys = [
        {
            name: "Institution",
            key: "institutionName",
        },
        {
            name: "State",
            key: "stateWithDescription",
        },
        {
            name: "Enviornment",
            key: "clientName",
        },
        {
            name: "Time",
            key: "executionDate",
        },
        {
            name: "Request Provider",
            key: "requestProviderName",
        },
        {
            name: "Error",
            key: "errorCode",
        },
        {
            name: "Customer ID",
            key: "customerId",
        },
        {
            name: "Request ID",
            key: "sessionId",
        },
        {
            name: "Login ID",
            key: "cardId",
        },
    ];
    
    return (
        <main>
            <h1>{institution}</h1>
            {loading && <h1>Loading...</h1>}
            {institution && !loading && error === "" && <Table data={data} keys={keys} />}
            {!institution && <h1>Specify an institution</h1>}
            <Error error={error} loading={loading} />
        </main>
    );
};

export default Requests;
