const Error = ({ error, loading }) => {
    return (
        error !== "" &&
        !loading && (
            <div className="error--message">
                <h1>
                    Something failed while getting the data. Check back in some
                    time!.
                </h1>

                <h3>{error}</h3>
                {error === "Failed to fetch" && (
                    <>
                        <h3>
                            Try to authenticate by clicking{" "}
                            <a href="/Statistics/Institute">here</a>
                            .{" "}Come back here after authenticating.
                        </h3>
                    </>
                )}

            </div>
        )
    );
};

export default Error;
