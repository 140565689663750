import Institution from "../components/Institution";
import Error from "../components/Error";

const Home = ({ dataToUse, typeOfDataToShow, error, loading }) => {
    return (
        <main>
            {loading && <h1>Loading...</h1>}
            {typeOfDataToShow === "0" && error === "" && !loading && (
                <Institution data={dataToUse} />
            )}
            {typeOfDataToShow !== "0" && error === "" && !loading && (
                <h1>Working on it, check back later !!</h1>
            )}
            <Error error={error} loading={loading} />
        </main>
    );
};

export default Home;
