import RotationTop from "../components/RotationTop";
import Error from "../components/Error";

const Rotation = ({ dataToUse, typeOfDataToShow, error, loading, country }) => {
    return (
        <main>
            {loading && <h1>Loading...</h1>}
            {typeOfDataToShow === "0" && error === "" && !loading && (
                <RotationTop data={dataToUse} country={country} />
            )}
            {typeOfDataToShow !== "0" && error === "" && !loading && (
                <h1>Working on it, check back later !!</h1>
            )}
            <Error error={error} loading={loading} />
        </main>
    );
};

export default Rotation;
