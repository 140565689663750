import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const RotationInstitution = ({dataToUse}) => {
    let [params] = useSearchParams();
    const [institution] = useState(params.get("name"))
    const [institutionData, setInstitutionData] = useState([])

    useEffect(() => {
        getInstitution(dataToUse);
    }, [dataToUse]);

    const getInstitution = (dataToUse) => {
        setInstitutionData(dataToUse.filter(element => element.institution === institution)[0])
    }

    return(<h1>{institutionData.institution} | {institutionData.successRate}</h1>)
};

export default RotationInstitution;
