import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TableRow from "./TableRow";

const Table = ({ data, keys }) => {

    const location = useLocation();

    const [dataToUse, setDataToUse] = useState(data);
    const [sortDirectionDescending, setSortDirection] = useState(true);
    const [sortOn, setSortOn] = useState("");

    const [columnsShown, setColumnsShown] = useState(keys);
    const [columnsHidden, setColumnsHidden] = useState([]);

    useEffect(() => {
        setDataToUse(data);

        if (localStorage.getItem(`${location.pathname}-columnsShown`) != null) {
            setColumnsShown(JSON.parse(localStorage.getItem(`${location.pathname}-columnsShown`)));
        }

        if (localStorage.getItem(`${location.pathname}-columnsHidden`) != null) {
            setColumnsHidden(JSON.parse(localStorage.getItem(`${location.pathname}-columnsHidden`)));
        }
    }, [data]);

    const sortData = (element) => {
        setSortOn(element.key);
        const dataType = typeof dataToUse[0][element.key];
        if (dataType === "string") {
            sortDataString(element.key);
        } else if (dataType === "number") {
            sortDataNumber(element.key);
        }
    };

    const sortDataString = (key) => {
        setDataToUse(
            [...dataToUse].sort((a, b) => {
                if (sortDirectionDescending) {
                    setSortDirection(false);
                    return a[key].toLowerCase() >
                        b[key].toLowerCase()
                        ? 1
                        : -1;
                } else {
                    setSortDirection(true);
                    return a[key].toLowerCase() <
                        b[key].toLowerCase()
                        ? 1
                        : -1;
                }
            })
        );
    };

    const sortDataNumber = (key) => {
        setDataToUse(
            [...dataToUse].sort((a, b) => {
                if (sortDirectionDescending) {
                    setSortDirection(false);
                    return b[key] - a[key];
                } else {
                    setSortDirection(true);
                    return a[key] - b[key];
                }
            })
        );
    };

    const getSortDirection = () => (sortDirectionDescending ? "↑" : "↓");

    const removeColumn = (element) => {
        localStorage.setItem(
            `${location.pathname}-columnsShown`,
            JSON.stringify(columnsShown.filter((e) => e.key !== element.key))
        );
        localStorage.setItem(
            `${location.pathname}-columnsHidden`,
            JSON.stringify([...columnsHidden, element])
        );

        setColumnsShown(columnsShown.filter((e) => e.key !== element.key));
        setColumnsHidden([...columnsHidden, element]);
    };

    const addColumn = (element) => {
        localStorage.setItem(
            `${location.pathname}-columnsShown`,
            JSON.stringify([...columnsShown, element])
        );
        localStorage.setItem(
            `${location.pathname}-columnsHidden`,
            JSON.stringify(columnsHidden.filter((e) => e.key !== element.key))
        );

        setColumnsShown([...columnsShown, element]);
        setColumnsHidden(columnsHidden.filter((e) => e.key !== element.key));
    };

    const searchInstitutions = (e) => {
        let searchTerm = e.target.value;
        if (searchTerm === "") {
            setDataToUse(data);
            return;
        }

        const searchData = data.filter(element => (
            element.institution.toLowerCase().includes(searchTerm.toLowerCase())
        ))

        setDataToUse(searchData)
    }

    return (
        <section className="table--container">
            {columnsHidden.length !== 0 && (
                <ul className="columns--list">
                    {columnsHidden.map((element) => (
                        <li
                            className="columns--list--item"
                            key={element.key}
                            onClick={() => addColumn(element)}
                        >
                            + {element.name}
                        </li>
                    ))}
                </ul>
            )}
            <table>
                <thead>
                    <tr>
                        <td className="search" colSpan={100}>
                            <input
                                className="search--input"
                                type="text"
                                placeholder="Search institutions ..."
                                onChange={searchInstitutions}
                            />
                        </td>
                    </tr>
                    <tr>
                        {columnsShown.map((element) => (
                            <th key={element.key}>
                                <span>
                                    <h4>{element.name}</h4>
                                    <button
                                        className="btn--sort"
                                        onClick={() => sortData(element)}
                                    >
                                        {sortOn === element.key
                                            ? getSortDirection()
                                            : "↑↓"}
                                    </button>
                                    {element.name !== "Institution" && (
                                        <button
                                            className="btn--remove"
                                            onClick={() =>
                                                removeColumn(element)
                                            }
                                        >
                                            -
                                        </button>
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <TableRow data={dataToUse} columnsShown={columnsShown} />
                </tbody>
            </table>
        </section>
    );
};

export default Table;
