import { useState, useEffect } from "react";

import keys from "../data/keys.json"
import topInstitutions from "../data/topInstitutions.json";

import Table from "./table/Table";

const RotationTop = ({ data, country }) => {
    const [dataToUseCanada, setDataToUseCanada] = useState(data);
    const [dataToUseUS, setDataToUseUS] = useState(data);

    useEffect(() => {
        filterTopFIs(data);
    }, [data]);

    let filterTopFIs = (data) => {
        const topFIsCanada = data.filter((element) =>
            topInstitutions[0].CA.includes(element.institution)
        );

        const topFIsUS = data.filter((element) =>
            topInstitutions[0].US.includes(element.institution)
        );

        setDataToUseCanada(topFIsCanada);
        setDataToUseUS(topFIsUS);
    };

    let statsInstitutions = () => {
        let data = country === "0" ? dataToUseCanada : dataToUseUS;
        return data.map((i) => ({
            institution: i.institution,
            totalRequests: i.totalRequests,
            nbPendingAuth: i.nbPendingAuth,
            nbInfoCompleted: i.nbInfoCompleted,
            nbValidCred: i.nbValidCredentials,
            nbRetryLater: i.nbRetryLater,
            nbBankMaintenance: i.nbBankMaintenance,
            nbOfTryAvg: i.nbOfTryAverage,
            nbUnrelated: i.nbUnrelated,
            nbHasError: i.nbHasError,
            retryRate: parseFloat((i.retryRate * 100).toFixed(2)),
            dropOutRate: parseFloat((i.dropOutRate * 100).toFixed(2)),
            successRate: parseFloat((i.successRate * 100).toFixed(2)),
            internalSuccessRate: parseFloat(i.internalSuccessRate.toFixed(2)),
            cardConversionRate: parseFloat(
                (i.cardConversionRate * 100).toFixed(2)
            ),
            requestConversionRate: parseFloat(
                (i.requestConversionRate * 100).toFixed(2)
            ),
            unrelatedRate: i.unrelatedRate,
            hasErrorRate: parseFloat((i.hasErrorRate * 100).toFixed(2)),
            nbCardUnique: i.nbCardUnique,
            nbCardUniqueSuccess: i.nbCardUniqueSuccess,

            authTimeFirst: i.authTimeFirst,
            authTimeSecond: i.authTimeSecond,

            nbMFA: i.nbAnsSecChallenge,
        }));
    };

    return <Table data={statsInstitutions()} keys={keys} />;
};

export default RotationTop;
