import { Link, useLocation } from "react-router-dom";

import ca from "../../data/ca.json";
import us from "../../data/us.json";

const TableRow = ({ data, columnsShown }) => {
    const location = useLocation()

    const rowFilters = [...ca, ...us];

    const getFilteredClass = (row, field) => {
        const filters = rowFilters.filter(
            (e) => e.institution === row.institution
        );

        if (filters.length === 1) {
            // check SR
            const SR = row.successRate;
            const CR = row.cardConversionRate;

            const expectedSR = filters[0].expectedSR;
            const monitoringSR = filters[0].monitoringSR;
            const expectedCR = filters[0].expectedCR;
            const monitoringCR = filters[0].monitoringCR;

            if (SR > expectedSR && field === "successRate") {
                return "cell--expected";
            } else if (SR < monitoringSR && field === "successRate") {
                return "cell--alarming";
            } else if (
                SR >= monitoringSR &&
                SR <= expectedSR &&
                field === "successRate"
            ) {
                return "cell--monitoring";
            }

            if (CR > expectedCR && field === "cardConversionRate") {
                return "cell--expected";
            } else if (CR < monitoringCR && field === "cardConversionRate") {
                return "cell--alarming";
            } else if (
                CR >= monitoringCR &&
                CR <= expectedCR &&
                field === "cardConversionRate"
            ) {
                return "cell--monitoring";
            }
        }
    };

    return data.map((row) => {
        return (
            <tr key={row.institution}>
                {columnsShown.map((element) => (
                    <td
                        className={getFilteredClass(row, element.key)}
                        key={element.key}
                    >
                        {element.key == "institution" && location.pathname != "/requests" ? <Link to={`/requests?name=${row[element.key]}`}>{row[element.key]}</Link> : row[element.key]}
                    </td>
                ))}
            </tr>
        );
    });
};

export default TableRow;
